import { render, staticRenderFns } from "./index.vue?vue&type=template&id=dfdaf9c4&scoped=true"
import script from "./index.js?vue&type=script&lang=js&external"
export * from "./index.js?vue&type=script&lang=js&external"
import style0 from "./index.less?vue&type=style&index=0&id=dfdaf9c4&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfdaf9c4",
  null
  
)

export default component.exports